<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="sms" class="row">
              <b-form-group id="input-group-1" label-for="input-1" class="col-6">
                <label style="font-weight: 500">Name <span style="color: red">*</span></label>
                <b-form-input id="input-1" placeholder="Enter Name" v-model="form.name" :class="{
                  'is-invalid': submitted && $v.form.name.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                  Name is required.
                </div>
              </b-form-group>
              <b-form-group id="input-group-2" label-for="input-2" class="col-6">
                <label style="font-weight: 500">Subject</label>
                <b-form-input id="input-2" placeholder="Enter Subject" v-model="form.subject"></b-form-input>
              </b-form-group>
              <b-form-group label-for="in-forum" class="col-6">
              <label style="font-weight: 500">Forum</label>
                <multiselect @search-change="fetchForum" id="in-forum" v-model="form.brand_forum_id" :options="getForum"
                  :multiple="false" track-by="id" label="title" placeholder="Type here to search">
                  <span slot="noOptions">
                    Type here to search
                  </span>
                </multiselect>
              </b-form-group>
              <b-form-group id="input-group-1" label-for="input-1" class="col-6">
                <label style="font-weight: 500">Variable (Enter only number)</label>
                <b-form-input id="input-1" type="number" min="1" placeholder="Enter Variable" v-model="form.variables" oninput="validity.valid||(value='')">
                </b-form-input>
              </b-form-group>
              <b-form-group id="input-group-3" label-for="input-3" class="col-12">
                <label style="font-weight: 500">Email HTML Content <span style="color: red">*</span></label>
                <b-form-textarea id="input-3" rows="5" v-model="form.content" placeholder="Enter Email HTML Content"
                  :class="{
                    'is-invalid': submitted && $v.form.content.$error,
                  }">
                </b-form-textarea>
                <div v-if="submitted && !$v.form.content.required" class="invalid-feedback">
                  Email HTML Content is required.
                </div>
              </b-form-group>
              <div class="col-12">
                <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                    v-if="this.$route.name == 'add-sms-notification'">Save Data</span>
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import notificationSMS from "../../../../mixins/ModuleJs/notification-sms";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, notificationSMS],
  data() {
    return {
      submitted: false,
      title: "SMS",
      items: [
        {
          text: "Back",
          href: "/sms-notification",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      content: { required },
    },
  },
};
</script>
